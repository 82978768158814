@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: Arial, sans-serif;
  background-color: #f6f7f9;
  /* background-image: url("./img/background_register.png"); */
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

header {
  background-color: #c03c38;
  color: #fff;
  padding: 10px;
  text-align: center;
}

h1 {
  text-align: left;
  margin: 0;
  font-size: 2em;
}
nav {
  /* Shift the navigation to the right */
  text-align: right;
  margin-right: 20px; /* Adjust the margin as needed */
}

nav ul {
  list-style: none;
  padding: 0;
}

nav li {
  display: inline;
  margin: 10px;
}

a {
  font-family: Arial, sans-serif;
  text-decoration: none;
  color: #ffffff;
}

section {
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  border-radius: 5px;
}

#hero {
  /* background-image: url('hero-image.jpg'); */
  background-size: cover;
  text-align: center;
  padding: 100px;
}

.btn {
  background-color: #c03c38;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

#donors, #recipients {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

footer {
  background-color: #c03c38;
  color: #fff;
  text-align: center;
  padding: 10px;
}



